.menu-container {
    position: relative;
}

.menu-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    min-width: 20%;
    height: 100vh;
    background-color: white;
    padding: 10px;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.buttons-menu {
    display: flex;
    justify-content: space-between;
}

button {
    height: 30px;
    margin-bottom: 10px;
}

.data-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.data-infos:nth-child(3) {
    align-items: self-start;
}

.route-name {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.route-name div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.route-name div button,
.route-name div input {
    margin-bottom: unset;
    min-height: 25px;
}